import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-history-modal',
  templateUrl: './order-history-modal.component.html',
  styleUrls: ['./order-history-modal.component.scss']
})
export class OrderHistoryModalComponent implements OnInit {
  @Input() modalObj: any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onCloseModal() {

  }
  getStatus(status) {
    const statusObj = [...status].pop();
    return statusObj.value;

  }
}
