export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const EMAIL_PATTERN = /^(([^<>!#$%&\^\*()\[\]\\.,;:\s@'"?`~]+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const COUNTER = 2;
export const SECONDS = 59;
export const TABLE_HEADERS = [
  'Door#',
  'Alert Type',
  'Expected Value',
  'Actual Value',
  'Date & Time',
  'Actions',
];
export const TaskList = [
  {
    displayName: 'New Order', route: '/dashboard/new-order',
    permissionKey: 'singleOrder', background: '#eeccff', labelFor: 'Support',
  },
  {
    displayName: 'Bulk Order', route: '/dashboard/bulk-order',
    permissionKey: 'bulkOrder', background: '#eeccff', labelFor: 'Support'
  },
  {
    displayName: 'Order History', route: '/dashboard/order-history',
    permissionKey: 'orderHistory', background: '#eeccff', labelFor: 'Support'
  },
  {
    displayName: 'Update Status', route: '/dashboard/update-status',
    permissionKey: 'orderHistory', background: '#eeccff', labelFor: 'Support'
  },
];

export const STATUS_LIST = [
  { key: 'outForDelivery', value: 'Out For Delivery' },
  { key: 'delivered', value: 'Delivered' },
  { key: 'orderPlaced', value: 'Order Placed' },
  { key: 'cancelled', value: 'Cancelled' }
]

export const STATUS={
  'outForDelivery': 'Out For Delivery',
  'delivered': 'Delivered',
  'orderPlaced': 'Order Placed',
  'cancelled': 'Cancelled'
}

export const ALERTS_LIST = [
  {
    name: 'Time Delayed',
    value: 'timeDelayed',
  },
  {
    name: 'Over Speed',
    value: 'overSpeeding',
  },
  {
    name: 'Under Speed',
    value: 'underSpeeding'
  },
  {
    name: 'Dumping Delayed',
    value: 'excavDelay'
  },
  {
    name: 'Excavting Delay',
    value: 'dumpDelay'
  }
];

export const VEHICLE_TYPE = [
  {
    name: 'Trucks',
    value: 'truck',
    background: '#F9C74F',
  },
  {
    name: 'Excavator',
    value: 'excavator',
    background: '#F94144',
  },
  {
    name: 'Tanker',
    value: 'tanker',
    background: '#48CAE4',
  },
  {
    name: 'Dozer',
    value: 'dozer',
    background: '#F8961E',
  },
  {
    name: 'Grader',
    value: 'grader',
    background: '#90BE6D',
  },
  {
    name: 'Drill',
    value: 'drill',
    background: '#ADB5BD',
  },
];

export const VEHICLE_ACTIONS = [
  {
    name: 'Moving',
    value: 'moving',
    // background: '#ffc107',
  },
  {
    name: 'Stopped',
    value: 'stopped',
    // background: '#FF0000',
  }
];

export const ZONES = [
  {
    name: 'Dumping',
    value: 'dumping',
  },
  {
    name: 'Garage',
    value: 'garage',
  },
  {
    name: 'Excavating',
    value: 'excavating'
  },
  {
    name: 'enRoute',
    value: 'enroute'
  }
];

export const VEHICLE_LIST = ['179', '193', '194', '196', '199', '201', '202', '203', '204', '205', '206', '207', '208', '209', '210',
  '211', '212', '213', '214', '215', '216', '217', '218', '219', '220',
  '221', '222', '223', '224', '225', '226', '227', '228', '229', '230',
  '231', '232', '233', '234', '235', '236', '237', '242', '245', '246', '247', '248', '249', '250',
  '251', '252', '253', '254', '255', '256', '257', '258',
  'Diesel Tanker-1', 'Diesel Tanker-2', 'Dozer-2', 'Dozer-6',
  'Drill Komatsu-1', 'Drill Komatsu-2', 'Drill Volvo-3', 'Grader-1', 'Grader-2',
  'Tata Hitachi Machine-6', 'Tata Hitachi Machine-7', 'Tata Machine-8',
  'Volvo Machine-1', 'Volvo Machine-2', 'Volvo Machine-3', 'Volvo Machine-4', 'Volvo Machine-9',
];

export function getConfig(type) {
  const CONFIG = {
    CONFIRM_MODAL_CONFIG: {
      windowClass: 'confirmation-modal-css',
      backdrop: true,
      scrollable: false,
      centered: true
    },
    OTP_MODAL_CONFIG: {
      windowClass: 'otp-modal-mobile-css',
      backdrop: true,
      scrollable: false,
      centered: true
    },
    ORDER_HISTORY_MODAL: {
      windowClass: 'modal fade come-from-modal right',
      backdrop: true,
      scrollable: false,
      centered: true
    }
  };

  return CONFIG[type];
}


export const VALIDATION_DICTIONARY = {
  required: 'required',
  pattern: 'pattern',
  maxlength: 'maxlength',
  minlength: 'minlength',
  email: 'email',
  min: 'min',
  max: 'max'
};

export const SHIFTS = [
  { startTime: '0400', endTime: '1159', shiftString: '04AM - 12PM' },
  { startTime: '1200', endTime: '1959', shiftString: '12PM - 08PM' },
  { startTime: '2000', endTime: '2359', shiftString: '08PM - 04AM' },
];
export const ADDRESS_BY_CITY = {
  types: [],
  componentRestrictions: { country: 'IN' },
  fields: ['address_components', 'geometry', 'place_id', 'formatted_address'],
};
