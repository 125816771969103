import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private toasterService: ToastService, private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('called response interceptor', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log(event.body);
          if (event.body.type === 0) {
            // this.toasterService.presentToast(event.body.message, '', 'error');
            return event;
          }
        }
        return event;
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (Math.floor(err.status / 100) === 5) {
            this.toasterService.presentToast(err.error.message, 'Error', 'error');
          }
          console.log(err);
          return throwError(err);
        }
      })
    );
  }
}
