import { ToastService } from './../../services/toast.service';
import { OrderHistoryService } from './../../../modules/order-history/service/order-history.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { STATUS_LIST } from 'src/app/core/constants/app-constants';

@Component({
  selector: 'app-update-order-status-modal',
  templateUrl: './update-order-status-modal.component.html',
  styleUrls: ['./update-order-status-modal.component.scss']
})
export class UpdateOrderStatusModalComponent implements OnInit {
  @Input() modalObj: any;
  statusList = STATUS_LIST;
  statusVal = '';
  constructor(public activeModal: NgbActiveModal,
    private orderHistoryService: OrderHistoryService,
    private toast: ToastService) { }

  ngOnInit() {
  }
  onUpdateStatus() {
    if (this.statusVal) {
      const reqBody = {
        'orderId': this.modalObj.orderId,
        'status': this.statusVal
      }
      this.orderHistoryService.updateStatus(reqBody).then((res) => {
        this.activeModal.close();
      });
    } else {
      this.toast.presentToast('Please select status', '', 'error');
    }
  }

}
