import { Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { StorageService } from './core/services/storage/storage.service';
import { AppData } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private storageService: StorageService,
    private appData: AppData,
    private authService: AuthService) {

  }
  title = 'hello-rider-dashboard';
}
