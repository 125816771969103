import { Router } from '@angular/router';
import { AccountService } from './../../../modules/account/service/account.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { COUNTER, SECONDS } from 'src/app/core/constants/app-constants';
import { mustMatch } from '../../utils/common-functions';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpModalComponent implements OnInit, OnDestroy {
  @Input() modalObj: any;
  otpForm: FormGroup;
  setPasswordForm: FormGroup;
  mobileForm: FormGroup;

  isOTPSubmitted = false;
  isSetPdSubmitted = false;
  isMobileSubmitted = false;

  isVerifyDisable = true;

  isOTPView = false;
  isSetPasswordView = false;
  isMobileView = false;

  counter = COUNTER;
  interval: any;
  showResend = false;
  seconds = SECONDS;
  verificationSignature;
  mobile;
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    console.log(this.modalObj);
    if (this.modalObj['type'] === 'FROM_OTP') {
      this.forGetOtp(this.modalObj);
      this.mobile = this.modalObj['mobile'];
      this.isOTPView = true;
    } else {
      this.isMobileView = true;
    }

    // get otp form init
    this.otpFormInit();

    //set password form init
    this.setPasswordFormInit();

    //mobile number form init
    this.mobInitForm();
  }

  mobInitForm() {
    this.mobileForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.min(1111111111), Validators.max(9999999999)]]
    });
  }

  otpFormInit() {
    this.otpForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.min(1000), Validators.max(9999)]]
    });

    this.onOTPValuesChange();
  }

  onChangeMobile() {
    if (this.mobileForm.controls['mobile'].value != '') {
      this.isMobileView = true;
      this.isOTPView = false;
    } else {
      this.activeModal.dismiss();
    }
  }
  onOTPValuesChange() {
    this.otpForm.valueChanges.subscribe((res) => {
      const otp = String(res.otp);
      if (otp != 'null' && otp.length === 4) {
        this.isVerifyDisable = false;
      } else {
        this.isVerifyDisable = true;
      }
    });
  }

  setPasswordFormInit() {
    this.setPasswordForm = this.fb.group({
      'password': ['', [Validators.required]],
      'confirmPassword': ['', [Validators.required]],
    }, {
      validator: mustMatch('password', 'confirmPassword')
    });
  }

  // Timer function for resend the OTP
  startTimer() {
    this.interval = setInterval(() => {
      if (this.seconds > 1) {
        this.showResend = false;
        this.seconds--;
      } else {
        this.showResend = true;
        clearInterval(this.interval);
        if (this.counter === 0) {
          this.showResend = false;
          this.counter = -1;
        }
      }
    }, 1000);
  }

  onResendOTP() {
    const body = { 'mobile': '+91' + String(this.modalObj['mobile']) }
    this.accountService.resendOtp(body).then((res) => {
      this.seconds = 59;
      if (this.counter >= 0) {
        this.startTimer();
        this.counter--;
      }
    });
  }

  onVerifyOTP() {
    this.isOTPSubmitted = true;
    const body = this.otpForm.value;
    body['mobile'] = '+91' + String(this.modalObj['mobile']);
    this.accountService.verifyOTP(body).then((res) => {
      this.isOTPView = false;
      this.isSetPasswordView = true;
      const responseData = res['body']['data'];
      this.verificationSignature = responseData['verificationSignature'];
      console.log(this.verificationSignature);
    });
  }

  forGetOtp(obj) {
    const body = { mobile: '+91' + String(obj['mobile']) }
    this.accountService.sendOtp(body).then((res) => {
      this.seconds = 59;
      this.startTimer();
    });
  }

  objForSignUpAndRestPassword() {
    const body = {
      'password': this.setPasswordForm.controls['password'].value,
      'verificationSignature': this.verificationSignature
    }
    return body;
  }

  async onPasswordSubmit() {
    const reqBody = await this.objForSignUpAndRestPassword();
    if (this.modalObj['type'] === 'FROM_OTP') {
      reqBody['mobile'] = this.modalObj['mobile'];
      reqBody['name'] = this.modalObj['name'];
      this.accountService.signUp(reqBody);
    } else {
      reqBody['mobile'] = this.mobileForm.controls['mobile'].value;
      this.accountService.resetPassword(reqBody).then((res) => {
        this.modalService.dismissAll();
      });
    }
  }

  onMobileSubmit() {
    this.isMobileSubmitted = true;
    if (this.mobileForm.valid) {
      console.log(this.mobileForm.value);
      const body = this.mobileForm.value;
      this.accountService.sendOtp(body).then((res) => {
        this.seconds = 59;
        this.startTimer();
        this.isMobileView = false;
        this.isOTPView = true;
        this.mobile = this.mobileForm.value['mobile'];
      });
    } else {
      return;
    }
  }

  //Started form validation
  get mobControl() { return this.mobileForm.controls; }
  get otpControl() { return this.otpForm.controls; }
  get setPdControl() { return this.setPasswordForm.controls; }

  getMobValid(fieldName) {
    if (this.mobControl[fieldName].invalid && (this.mobControl[fieldName].dirty || this.isMobileSubmitted)) {
      return true;
    }
    return false;
  }

  getOTPValid(fieldName) {
    if (this.otpControl[fieldName].invalid && (this.otpControl[fieldName].dirty || this.isOTPSubmitted)) {
      return true;
    }
    return false;
  }
  getPdValid(fieldName) {
    if (this.setPdControl[fieldName].invalid && (this.setPdControl[fieldName].dirty || this.isSetPdSubmitted)) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
