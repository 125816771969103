import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AppData {
  // URLS
  static baseUrl = environment.baseUrl;
  // behaviour subjects
  static token$ = new BehaviorSubject(null);
  static accountData$ = new BehaviorSubject(null);
  static userDataSubject$ = new BehaviorSubject(null);
  // below will be used for checking if we have to change pointer of side menu
  static appPermissionCheck$ = new BehaviorSubject('init');

  static userData: UserModel;
  static isAuthenticated = false;

  constructor(private storage: StorageService) {
    if (this.storage.getItem('token') !== null) {
      AppData.token$.next(this.storage.getItem('token'));
      AppData.isAuthenticated = true;
    }

    if (this.storage.getItem('userData') !== null) {
      AppData.userDataSubject$.next(this.storage.getItem('userData'));
    }

    AppData.token$.subscribe((data: string) => {
      this.setToken(data);
    });

    AppData.userDataSubject$.subscribe((userData) => {
      this.storage.setItem('userData', userData);
      AppData.userData = Object.assign(new UserModel(), userData);
    });


  }

  setToken(token: string) {
    this.storage.setItem('token', token);
  }

  isAuthenticatedFunction() {
    return !(this.storage.getItem('token') === null);
  }


}
