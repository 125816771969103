import { CustomDirectiveModule } from './utils/custom-directives/custom-directive.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { OtpModalComponent } from './modals/otp/otp.component';
import { ConfirmDialogBoxComponent } from './modals/confirm-dialog-box/confirm-dialog-box.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { AlertCommentComponent } from './modals/alert-comment/alert-comment.component';
import { OrderHistoryModalComponent } from './modals/order-history-modal/order-history-modal.component';
import { UpdateOrderStatusModalComponent } from './modals/update-order-status-modal/update-order-status-modal.component';

@NgModule({
  declarations: [
    OtpModalComponent,
    ConfirmDialogBoxComponent,
    HomeComponent,
    AlertCommentComponent,
    OrderHistoryModalComponent,
    UpdateOrderStatusModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    RouterModule,
    CustomDirectiveModule

  ],
  entryComponents: [
    OtpModalComponent,
    ConfirmDialogBoxComponent,
    AlertCommentComponent,
    OrderHistoryModalComponent,
    UpdateOrderStatusModalComponent
  ],
  exports: [
    OtpModalComponent,
    ConfirmDialogBoxComponent,
    AlertCommentComponent,
    OrderHistoryModalComponent,
    UpdateOrderStatusModalComponent
  ]
})
export class SharedModule { }
