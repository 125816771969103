import { AppData } from './app-data.service';

/**
 * Type URL_KEYS for restricting the url entered
 */
export type URL_KEYS =
  // Accounts Related URLs
  | 'login'
  | 'verifyOtp'
  | 'sendOtp'
  | 'resendOtp'
  | 'signup'
  | 'resetPassword'

  | 'newOrder'
  | 'bulkOrder'
  | 'orderHistory'
  | 'updateStatus'
  ;

const UrlMapping = {
  // Accounts Related URLs
  login: {
    url: AppData.baseUrl + '/user/login',
    showMsg: true,
    showLoading: true,
  },
  verifyOtp: {
    url: AppData.baseUrl + '/user/otp/verify',
    showMsg: false,
    showLoading: true,
  },
  sendOtp: {
    url: AppData.baseUrl + '/user/otp/send',
    showMsg: false,
    showLoading: true,
  },
  resendOtp: {
    url: AppData.baseUrl + '/user/otp/resend',
    showMsg: true,
    showLoading: true,
  },
  signup: {
    url: AppData.baseUrl + '/user/signup',
    showMsg: true,
    showLoading: true,
  },
  resetPassword: {
    url: AppData.baseUrl + '/user/password/reset',
    showMsg: true,
    showLoading: true,
  },
  newOrder: {
    url: AppData.baseUrl + '/order/new',
    showMsg: true,
    showLoading: true,
  },
  bulkOrder: {
    url: AppData.baseUrl + '/order/bulkOrder',
    showMsg: true,
    showLoading: true,
  },
  orderHistory: {
    url: AppData.baseUrl + '/order/history',
    showMsg: true,
    showLoading: true,
  },
  updateStatus: {
    url: AppData.baseUrl + '/order/statusChange',
    showMsg: true,
    showLoading: true,
  }
};

/**
 * add url here for generating urls, all url entry is restricted to above mentioned URL_KEYS
 */

export class RequestUrl {
  static get(key: URL_KEYS): any {
    return UrlMapping[key];
  }
}
