import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-comment',
  templateUrl: './alert-comment.component.html',
  styleUrls: ['./alert-comment.component.scss']
})
export class AlertCommentComponent implements OnInit {
  @Input() alertObj: any;
  alertCommentForm;
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    console.log(this.alertObj);
    this.alertCommentForm = this.fb.group({
      comment: ['', [Validators.required]]
    });
  }

  save() {
    this.activeModal.dismiss({
      type: 'save',
      ...this.alertCommentForm.value
    });


  }
  dismiss() {
    this.activeModal.dismiss({ type: 'close' });

  }
}
