import { FormGroup } from '@angular/forms';

// for get single group input filed validation
export function getInputFieldValidation(formName, fieldName, isSubmitted?) {
    if (formName.controls[fieldName].invalid && (formName.controls[fieldName].dirty ||
        formName.controls[fieldName].touched || isSubmitted)) {
        return true;
    }
    return false;
}

// for get nested group input filed validation
export function getNestedGrpFieldValidation(formName, grpFieldName, fieldName, isSubmitted?) {
    if (formName.get(grpFieldName).get(fieldName).invalid && (formName.get(grpFieldName).get(fieldName).dirty ||
        formName.get(grpFieldName).get(fieldName).touched || isSubmitted)) {
        return true;
    }
    return false;
}
export function getSNestedGrpFieldValidation(formName, fGrpFieldName, sGrpFieldName, fieldName, isSubmitted?) {
    if (formName.get(fGrpFieldName).get(sGrpFieldName).get(fieldName).invalid && (formName.get(fGrpFieldName).get(sGrpFieldName).get(fieldName).dirty ||
        formName.get(fGrpFieldName).get(sGrpFieldName).get(fieldName).touched || isSubmitted)) {
        return true;
    }
    return false;
}


// filter list 
export function filterDataList(tempList, fieldName, searchStr) {
    return tempList.filter((inputObj) => {
        return inputObj[fieldName].toLowerCase().indexOf(searchStr) >= 0;
    });
}

// custom validator to check that two fields match
export function mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

