import { Injectable } from '@angular/core';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  constructor(private apiRequestService: ApiRequestService) { }

  orderHistoryData(reqBody) {
    return this.apiRequestService.post('orderHistory', reqBody);
  }

  updateStatus(reqBody) {
    return this.apiRequestService.post('updateStatus', reqBody);
  }
}
