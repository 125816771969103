export class UserModel {
  private userId: string;
  // name will contain the User's Name
  private name: string;
  // emailId and contact will be used for authentication.
  private mobile: string;
  private permissions: any;
  private restId: string;

  constructor() {
    this.userId = '';
    this.name = '';
    this.mobile = '';
    this.permissions = {};
    this.restId = '';
  }

  public get _userId(): string {
    return this.userId;
  }

  public set _userId(value: string) {
    this.userId = value;
  }

  public get _restId(): string {
    return this.restId;
  }

  public set _restId(value: string) {
    this.restId = value;
  }

  public get _name(): string {
    return this.name;
  }

  public set _name(value: string) {
    this.name = value;
  }

  public get _mobile(): string {
    return this.mobile;
  }

  public set _mobile(value: string) {
    this.mobile = value;
  }

  public get _permissions(): {} {
    return this.permissions;
  }

  public set _permissions(value: {}) {
    this.permissions = value;
  }

}
