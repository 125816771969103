import { Injectable } from '@angular/core';
import { ApiRequestService, AppData } from 'src/app/shared';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private apiService: ApiRequestService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  _sendOtp(reqBody) {
    return this.apiService.post('sendOtp', reqBody);
  }
  login(reqBody) {
    this.apiService.post('login', reqBody).then((res) => {
      if (res['body']['data']) {
        const responseData = res['body']['data'];
        console.log(responseData);
        AppData.token$.next(responseData['jwtToken']);
        AppData.isAuthenticated = true;
        // AppData.userIdSub$.next(responseData['userId']);
        AppData.userDataSubject$.next(responseData);
        this.router.navigate(['/dashboard']);
        // this.saveDataInLocalStorage(responseData);
      }
    });
  }

  verifyOTP(reqBody) {
    return this.apiService.post('verifyOtp', reqBody);
  }

  sendOtp(reqBody) {
    return this.apiService.post('sendOtp', reqBody);
  }

  resendOtp(reqBody) {
    return this.apiService.post('resendOtp', reqBody);
  }

  resetPassword(reqBody) {
    return this.apiService.put('resetPassword', reqBody);
  }

  signUp(reqBody) {
    this.apiService.post('signup', reqBody).then((res) => {
      const responseData = res['body']['data'];
      // AppData.token.next(responseData['token']);
      // this.saveDataInLocalStorage(reqBody);
      this.modalService.dismissAll();
      this.router.navigate(['login']);
    });
  }


}
